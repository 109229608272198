import React from 'react'
import conmebol from "../../assets/conmebol.svg"
import hash from "../../assets/hash.svg"

const Header = () => {
    return (
        <div className="box-header-ganadores">
            <a href="/tu-foto-con-la-libertadores">
                <img src={conmebol} className="icon-header "/>
            </a> 
            <img src={hash} className="icon-header hash"/>
        </div>
    )
}

export default Header
