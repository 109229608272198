import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import bannerdesktop from "../assets/banner-desktop.png";
import bannerMobile from "../assets/banner-mobile.png";
import legal from "../assets/legal.png";
import legalMobile from "../assets/legal-mobile.png";
import logos from "../assets/logos.png";
import cuerpo from "../assets/cuerpo.svg";
import cuerpoMobile from "../assets/cuerpo-mobile.svg";

const Ganadores = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const changed = useRef();
  const breakpoint = 600;
  useEffect(() => {
    changed.current = false;
    const calcWidth = () => {
      if (!changed.current) {
        setWidth(window.innerWidth);
      }
    };
    window.addEventListener("resize", calcWidth);
    return () => {
      changed.current = true;
      window.removeEventListener("resize", calcWidth);
    };
  }, []);
  return (
    <div>
      {width < breakpoint ? (
        <img src={bannerMobile} className="w-100" />
      ) : (
        <img src={bannerdesktop} className="w-100" />
      )}
      <Container>
        <div className="box-cuerpo">
          {width < breakpoint ? (
            <img src={cuerpoMobile} />
          ) : (
            <img src={cuerpo} />
          )}
        </div>
        <div className="box-redes-logos">
          <div className="logos">
            <img src={logos} />
          </div>
          <div className="box-redes">
            <div>
              <a href="https://www.facebook.com/amstel.pe" target="_blank">
                <i className="fab fa-facebook-f"></i>
              </a>
            </div>
            <div>
              <a
                href="https://www.youtube.com/channel/UCik8MTvY_roPHhlsjnKhmdw"
                target="_blank"
              >
                {" "}
                <i className="fab fa-youtube"></i>
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/amstel_pe/" target="_blank">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
        <figure className="box-legall">
          {width < breakpoint ? (
            <img src={legalMobile} className="w-100"></img>
          ) : (
            <img src={legal} className="w-100"></img>
          )}
        </figure>
      </Container>
    </div>
  );
};

export default Ganadores;
