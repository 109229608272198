import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyA5lSJnpWip0IfJz4wX8sMjGQm-NSLfShA",
    authDomain: "promoamstel.firebaseapp.com",
    projectId: "promoamstel",
    storageBucket: "promoamstel.appspot.com",
    messagingSenderId: "965362664570",
    appId: "1:965362664570:web:8edeed5af11c31b5d127c3",
    measurementId: "G-16Q8T8QT0N"
  };

  let db;
// Initialize Firebase
if (!firebase.apps.length) {
    const fb = firebase.initializeApp(firebaseConfig);
    db = fb.firestore();
}else{
    firebase.app();
    db = firebase.firestore();
}


export { db }

export const registerUser = async(dataForm) => {

    /**COUNT TOTAL DOCUMENTS */
    try{
        const {size} = await db.collection('usuarios').get();
        
        console.log(size);
        if(size!==0){
            console.log("Correctamente");
            dataForm.numRegister = dataForm.numRegister + size + 1;
            await db.collection("usuarios").doc().set({
                registro: {
                    dataForm
                }
            });
            return true;
        }else{
            return false;
        }
    }catch(e){
        console.log(e.message);
        return false;
    }
}


export const searchPhoto = async (dni) => {
    try{
        const docRef = await db.collection('copa_libertadores').doc(dni);
        
        const doc = await docRef.get();
    
        return doc.data();

    }catch(e){
        console.log(e);
    }
}

