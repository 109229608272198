import React from 'react'
import Loader from "react-loader-spinner";

export const LoaderUI = () => {
    return (
        <div className="container-loader">
            <Loader type="ThreeDots" color="#D20A11" height={80} width={80} />
        </div>
    )
}
