import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import Header from "./Header";
import groupMobile from "../../assets/group-photos-mobile_2.png";
import group from "../../assets/group-photos-desktop.png";
import { Container } from "react-bootstrap";
import footer from "../../assets/footer.svg";
import footerMobile from "../../assets/footer-mobile.svg";
import siguenos from "../../assets/siguenos.svg";
import siguenosDesktop from "../../assets/siguenos-desktop.svg";
import { searchPhoto } from "../../firebase";
import conmebol from "../../assets/conmebol.svg";

import axios from "axios";
import "./Photo.css";
import Swal from "sweetalert2";
import { LoaderUI } from "../Loader/LoaderUI";

const Search = () => {
  const router = useHistory();
  const location = useLocation();
  //Por si viene undefined
  const { q = "" } = queryString.parse(location.search);

  const [identity, setIdentity] = useState(q);
  const [dataTemp, setDataTemp] = useState({});
  const [finishSearching, setFinishSearching] = useState(false);
  const [isFound, setIsFound] = useState(false);
  const [beginSearching, setBeginSearching] = useState(false);
  const [onlyName, setOnlyName] = useState("");

  const handleInputChange = (e) => {
    setIdentity(e.target.value);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (identity === "") {
      router.push("/tu-foto-con-la-libertadores");
    } else {
      const outSpaces = identity.trim();
      router.replace(`?q=${outSpaces}`);
    }
  };

  const getInfoPerson = async () => {
    setBeginSearching(true);
    setFinishSearching(false);
    /* console.log(q); */
    const data = await searchPhoto(q);
    if (data) {
      //Existe
      setDataTemp(data);
      setIsFound(true);
      splitName(data); //Separar nombres
    } else {
      setDataTemp({});
      setIsFound(false);
    }
    setFinishSearching(true);
  };

  const handleDownloadImage = async () => {
    const responseImage = await axios({
      url: dataTemp.url,
      method: "GET",
      responseType: "blob",
    });

    /*     console.log(responseImage.data); */
    const url = window.URL.createObjectURL(new Blob([responseImage.data]));
    console.log(url);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${dataTemp.dni}.png`);
    document.body.appendChild(link);
    link.click();

    Swal.fire({
      title: '<p style="color : #cda434 ; font-size:30px">¡GRACIAS!</p>',
      imageUrl: 'https://promoamstel.com/logo-popup.svg',
      imageHeight: 100,
      imageAlt: "A tall image",
      html:
        "<p>Comparte tu experiencia con la copa que todo un continente quiere ganar </br> #JUNTOSPORLAPASION </p>",
      confirmButtonColor: "#D20A11",
    });
  };

  const splitName = (data) => {
    if (data.first_name) {
      const splitData = data.first_name.trim();
      const allNames = splitData.split(" ");
      /* console.log(allNames); */
      setOnlyName(allNames[0]);
    }
  };

  useEffect(() => {
    if (q != "") {
      getInfoPerson();
    } else {
      setBeginSearching(false); //No empieza buscando
      setFinishSearching(true);
      setDataTemp({});
    }
  }, [q]); //cuando cambie el parametro

  useEffect(() => {
    console.log("Home de nuevo");
  }, []);

  if (!finishSearching) {
    return <LoaderUI />;
  }
  return (
    <div className="fade-in animated">
      <Header />
      <Container>
        <div className="container-all-form">
          <div className="container-all-form-flex-reverse">
            <div className="box-group">
              {!isFound ? (
                <>
                  <img className="hide-desktop w-100" src={groupMobile} />
                  <img className="show-desktop w-100" src={group} />
                </>
              ) : (
                <div className="box-group-winner">
                  <img src={dataTemp.url} />
                </div>
              )}
            </div>
            <div className="box-group-form-flex">
              {!finishSearching ? (
                <p>Loading ....</p>
              ) : (
                <div className="box-title-photos">
                  <h1>
                    {beginSearching && !isFound && (
                      <>
                        ¡ups!
                        <br />
                        parece que hubo
                        <br />
                        un problema
                      </>
                    )}
                    {!beginSearching && (
                      <>
                        ¡Hola!
                        <br /> Bienvenido
                      </>
                    )}
                    {isFound && <>¡Hola {onlyName}!</>}
                  </h1>

                  <p className="p-gandores">
                    {beginSearching &&
                      isFound &&
                      "Al igual que tú vivimos y nos apasiona el fútbol, por eso te acercamos al trofeo con más historia de américa."}
                    {beginSearching &&
                      !isFound &&
                      "No hemos encontrado resultados, por favor vuelve a digitar tu número de documento para buscar tu foto."}
                    {!beginSearching &&
                      "Busca tu foto y comparte tu experiencia con la copa que todo un continente quiere ganar."}
                  </p>
                  <form className="box-form" onSubmit={submitForm}>
                    {isFound && finishSearching ? (
                      <div>
                        <div
                          className="btn-enviar-ganadores btn-descarga"
                          onClick={handleDownloadImage}
                        >
                          Descarga tu foto
                        </div>
                        <p className="p-disclaimer">
                          Descarga directa solo para Android, para usuarios IOS
                          presiona por 2 segundos la imagen y guárdala en tus
                          fotos.
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className="box-input box-input-ganadores">
                          <input
                            type="number"
                            className=""
                            placeholder="Ingresa tu número de documento"
                            name="docIdentity"
                            value={identity}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div
                          className="btn-enviar-ganadores"
                          onClick={submitForm}
                        >
                          <p>Buscar</p>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              )}
            </div>
          </div>
          {!isFound && (
            <div className="box-siguenos">
              <img src={siguenos} className="img-siguenos wobble-ver-left" />
              <img
                src={siguenosDesktop}
                className="img-siguenos-desktop wobble-ver-left"
              />
              <div className="box-redes">
                <div>
                  <a
                    href="https://www.instagram.com/amstel_pe/"
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.youtube.com/channel/UCik8MTvY_roPHhlsjnKhmdw"
                    target="_blank"
                  >
                    {" "}
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
                <div>
                  <a href="https://www.facebook.com/amstel.pe" target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>

      <div className="box-footer-ganadores">
        <img className="hide-desktop w-100" src={footerMobile} />
        <img className="show-desktop w-100" src={footer} />
      </div>
    </div>
  );
};

export default Search;
