
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Terminos from "./components/Terminos";
import lataAviso from "./assets/lata-aviso.svg";
import Gracias from "./components/Gracias";
import Ganadores from "./components/Ganadores";
import Search from "./components/Photo/Search";
import FormRegister from "./components/FormRegister";

function App() {
  // const MySwal = withReactContent(Swal);
  // MySwal.fire({
  //   confirmButtonColor: "#CAA15B",
  //   html: <img src={lataAviso} style={{ width: "90%" }} />,
  //   footer:
  //     '<a href="https://promoamstel.com/terminos-y-condiciones">*Ver nuevo cronograma en nuestros T&C</a>',
  //   timer: 5000,
  // });
  return (
    <Router>
      <div className="container-raffle">
        <Switch>
          {/* <Route exact path="/" component={FormRegister} /> */}
          {/* <Route exact path="/terminos-y-condiciones" component={Terminos} /> */}
          <Route exact path ="/" component={Ganadores} />
          <Route exact path ="/tu-foto-con-la-libertadores" component={Search} />
        </Switch>
      </div>
      <ToastContainer hideProgressBar position="top-center" />
    </Router>
  );
}

export default App;
